const NUMBER = /^[0-9]*$/

export default function () {
  return (value) => {
    if (!value) return
    if (typeof value === 'string' && !value.match(NUMBER)) {
      throw new Error(
        JSON.stringify({ id: 'reformulate.validate_number_invalid' }),
      )
    }
  }
}
