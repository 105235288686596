import React from 'react'
import classnames from 'classnames'

import style from './Card.css'

export function Card({
  as = 'div',
  bordered,
  children,
  className,
  emptyCard,
  shadowed,
  ...props
}) {
  const Element = as || 'div'

  return (
    <Element
      {...props}
      className={classnames(
        style.card,
        {
          [style.shadowed]: shadowed,
          [style.bordered]: bordered,
          [style.emptyCard]: emptyCard,
        },
        className,
      )}
    >
      {children}
    </Element>
  )
}
