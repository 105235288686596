import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import style from '../style.css'
import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'

export const InputType = {
  box: 'box',
  underline: 'underline',
}
/**
 * Input field.
 *
 * @component
 * @example
 * <InputField autoComplete="no" name="first_name" label="First Name" />
 */
function InputField({
  autoComplete = 'on',
  className,
  inputType = InputType.box,
  inputClassName,
  textarea,
  label,
  type = 'text',
  name,
  required = false,
  placeholder,
  showErrors = true,
  rows,
  onChange,
  onBlur,
  onFocus,
  validate,
  disabled = false,
  localValidator,
  readOnly = false,
  suffix,
  suffixclassName,
}) {
  const { getData, updateField, getFormattedError, addValidator } = useForm()
  const [validateAdded, setValidateAdded] = useState()
  const error = getFormattedError(name)
  const useBoxInputType = inputType === InputType.box

  useEffect(() => {
    if (validate && !validateAdded) {
      addValidator(name, localValidator)
      setValidateAdded(true)
    }
  }, [validateAdded])

  function handleChange({ target: { value } }) {
    const error = localValidator(value, { getData })

    updateField(name, { value, error })
    if (onChange) {
      onChange(value)
    }
  }

  function handleBlur({ target: { value } }) {
    const error = localValidator(value, { getData })

    updateField(name, { value, error })
    if (onBlur) {
      onBlur(value)
    }
  }

  function handleFocus({ target: { value } }) {
    const error = localValidator(value, { getData })

    updateField(name, { value, error })
    if (onFocus) {
      onFocus(value)
    }
  }

  return (
    <div className={style.root}>
      <div className={classnames(style.formField, className)}>
        {label && (
          <label
            className={classnames(style.formFieldLabel, {
              [style.formFieldLabelError]: error,
            })}
            htmlFor={name}
          >
            {label}
          </label>
        )}

        {(textarea && (
          <textarea
            placeholder={placeholder}
            disabled={disabled}
            className={classnames(
              { [style.boxFormFieldInput]: useBoxInputType },
              { [style.underlineFormFieldInput]: !useBoxInputType },
              { [style.formFieldInputError]: error },
              inputClassName,
            )}
            rows={rows}
            required={required}
            value={getData(name) || ''}
            onChange={handleChange}
            data-test={`${name}-input`}
            readOnly={readOnly}
          />
        )) || (
          <input
            autoComplete={autoComplete}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            className={classnames(
              { [style.boxFormFieldInput]: useBoxInputType },
              { [style.underlineFormFieldInput]: !useBoxInputType },
              { [style.formFieldInputError]: error },
              inputClassName,
            )}
            required={required}
            value={getData(name) || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            data-test={`${name}-input`}
            readOnly={readOnly}
          />
        )}

        {showErrors && error && (
          <div
            data-test={`${name}-error-message`}
            className={style.formFieldErrorMessage}
          >
            {error}
          </div>
        )}
        <div
          className={suffixclassName ? suffixclassName : style.suffixContainer}
        >
          {suffix}
        </div>
      </div>
    </div>
  )
}

export default withValidator()(InputField)
