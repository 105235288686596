import React from 'react'
import classnames from 'classnames'

import style from './ListItem.css'

export const ListItem = ({ children, indicator, selected, item, onClick }) => {
  function handleClick(event) {
    event.preventDefault()
    onClick(item)
  }

  return (
    <li
      className={classnames(style.item, {
        [style.selected]: selected,
      })}
    >
      <a className={style.link} onClick={handleClick}>
        {children}
      </a>
      {indicator && <div className={style.indicator} />}
    </li>
  )
}
