import React from 'react'
import { CompositeDecorator } from 'draft-js'

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

const Link = (props) => {
  const { children, entityKey, contentState } = props
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <span className="TooltipEntity__text">{children}</span>
    </a>
  )
}

export default new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
])
