import React, { useRef } from 'react'
import classnames from 'classnames'
import { Loader } from 'semantic-ui-react'

import { BaseUploadInput } from '../../../forms/index'
import { useForm } from '../../../forms/Form/FormContext'
import { withValidator } from '../../../forms/validators/index'
import style from './SquareImageUploadField.css'
import sharedStyle from '../style.css'

function SquareImageUploadField({
  className,
  disabled,
  image,
  label,
  localValidator,
  name,
  recommendedWidth = 120,
  recommendedHeight = 120,
  required,
  showLoader = true,
  showErrors = true,
  uploadFn,
}) {
  if (!uploadFn) {
    throw new Error('The "uploadFn" prop is not set.')
  }

  const childRef = useRef()
  const { getData, getFormattedError, updateField } = useForm()
  const error = getFormattedError(name)

  async function handleChange(file) {
    try {
      const value = await uploadFn(file)
      const error = localValidator(value, { getData, updateField })
      updateField(name, { value, error })
    } catch (error) {
      const errorMessage = 'Error while uploading the Image to the Server.'
      updateField(name, { error: errorMessage })
    }
  }

  function handleError(error) {
    updateField(name, { error })
    throw new Error(error)
  }

  return (
    <>
      {label && (
        <label
          className={classnames(sharedStyle.formFieldLabel, {
            [sharedStyle.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}{' '}
      <div
        className={classnames(className || style.container)}
        {...{ required }}
      >
        <BaseUploadInput
          className={style.input}
          name={name}
          onChange={handleChange}
          onError={handleError}
          ref={childRef}
          src={image}
          {...{ disabled, required }}
        />
        <div
          className={style.uploadIcon}
          onClick={() => childRef.current.openDialog()}
        >
          <div className={style.controls}>
            <div>
              Icon should be {recommendedWidth}px × {recommendedHeight}px
            </div>
            <div className={style.editLink}>Edit</div>
          </div>
        </div>
        {showLoader && (
          <Loader
            data-test={`${name}-image-loader`}
            style={{
              position: 'absolute',
              left: '50px',
            }}
            active
          />
        )}
      </div>
      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={sharedStyle.formFieldErrorMessage}
        >
          {error}
        </div>
      )}
    </>
  )
}

export default withValidator()(SquareImageUploadField)
