import React from 'react'
import classnames from 'classnames'

import { ListItem } from './ListItem'
import style from './ListMenu.css'

export const ListMenu = ({
  items = [],
  headerTitle,
  selectedItem,
  onClick,
  className,
}) => (
  <div className={classnames(style.container, className)}>
    {headerTitle && <div className={style.header}>{headerTitle}</div>}

    <ul className={style.list}>
      {items.map((item) => (
        <ListItem
          key={item.value}
          item={item}
          indicator={item.showIndicator}
          selected={selectedItem === item.value}
          onClick={onClick}
        >
          {item.text}
        </ListItem>
      ))}
    </ul>
  </div>
)
