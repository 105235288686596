import React from 'react'

import style from './VerticalTabMenu.css'

export const VerticalTabMenu = ({ children }) => (
  <div className={style.container}>
    <ul className={style.list}>
      {children}
    </ul>
  </div>
)
