import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export function HeaderDropdown({
  logged,
  me,
  dropdownItems,
  onLinkClick,
  testId = 'header-dropdown',
}) {
  const onItemClick = (event, url) => {
    event.preventDefault()
    onLinkClick(url)
  }

  let dropdownHeaderText = 'Sign in or Sign up'

  if (logged) {
    dropdownHeaderText = (me && me.name && me.name.display) || 'Loading...'
  }

  return (
    <Dropdown data-test={testId} pointing="top right" text={dropdownHeaderText}>
      <Dropdown.Menu>
        {dropdownItems.map((item, index) => (
          <React.Fragment key={index}>
            {((!logged && !item.showWhenLoggedIn) ||
              (logged && item.showWhenLoggedIn)) && (
              <>
                {item.showDivider && <Dropdown.Divider />}
                <Dropdown.Item
                  text={item.name}
                  as="a"
                  href={item.url}
                  onClick={(event) => onItemClick(event, item)}
                />
              </>
            )}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
