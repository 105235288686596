import React from 'react'

import style from './VerticalTabMenuItemLink.css'
import { ExternalLinkIcon } from '../../icons/light.jsx'

export const VerticalTabMenuItemLink = ({
  text,
  url
}) => {

  const handleClick = () => window.open(url)

  return (
    <div className={style.container} onClick={handleClick}>
      <label>{text}</label>
      <ExternalLinkIcon size="lg" />
    </div>
  )
}
