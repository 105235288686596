import React from 'react'
import { Grid } from 'semantic-ui-react'
import { withValidator } from '../../forms/validators/index'
import validate from '../../forms/validators/index'
import PasswordInputField from '../../custom/InputField/PasswordInputField/PasswordInputField.jsx'
import { useForm } from '../../forms/Form/FormContext'
import style from './PasswordStepForm.css'

export const ChangePasswordStep = ({
  validators,
  confirmValidators,
  placeholder = 'Choose a password',
  confirmPlaceholder = 'Enter password again',
}) => {
  const { getData } = useForm()

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <PasswordInputField
            className={style.passwordInput}
            inputType="underline"
            name="password"
            placeholder={placeholder}
            validate={
              validators ||
              validate.combine(validate.notEmpty(), validate.isPasswordStrong())
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <PasswordInputField
            className={style.passwordInput}
            inputType="underline"
            name="confirmPassword"
            placeholder={confirmPlaceholder}
            validate={
              confirmValidators || validate.isSamePassword(getData('password'))
            }
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default withValidator()(ChangePasswordStep)
