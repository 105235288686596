import React, { useState, useEffect } from 'react'
import { Checkbox } from 'semantic-ui-react'
import classnames from 'classnames'

import style from '../style.css'
import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'

function handleChange(value, props, context) {
  const { name, localValidator } = props
  const { updateField } = context
  const error = localValidator(value, context)

  updateField(name, { value, error })
}

function CheckboxField({
  className,
  label,
  type,
  name,
  showErrors = true,
  disabled = false,
  required,
  localValidator,
  validate,
}) {
  const { getData, updateField, getFormattedError, addValidator } = useForm()
  const [validateAdded, setValidateAdded] = useState()
  const error = getFormattedError(name)

  useEffect(() => {
    if (validate) {
      addValidator(name, localValidator)
      setValidateAdded(true)
    }
  }, [validateAdded])

  return (
    <div className={classnames(style.formField, className)} {...{ required }}>
      <Checkbox
        checked={!!getData(name)}
        onChange={(_, data) =>
          handleChange(
            data.checked,
            { name, localValidator },
            {
              getData,
              updateField,
            },
          )
        }
        {...{ className, label, type, disabled, required }}
      />

      {showErrors && error && (
        <div className={style.formFieldErrorMessage}>{error}</div>
      )}
    </div>
  )
}

export default withValidator()(CheckboxField)
