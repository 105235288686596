import React from 'react'
import { Loader } from 'semantic-ui-react'

import { Card } from '../../Card/Card.jsx'
import style from './ListLoader.css'

export const ListLoader = ({ fetched, total, loading }) => {
  if (loading) {
    return (
      <Card className={style.loading} data-test="list-loader">
        <Loader
          style={{
            position: 'relative',
            left: '26px',
            top: '16px',
            marginRight: '30px',
          }}
          active
        />
        <span>
          {fetched} / {total}
        </span>
      </Card>
    )
  }

  return null
}
