import React, { PureComponent } from 'react'
import { Button, Modal } from 'semantic-ui-react'

import style from './SelectionModal.css'

export const ACTION = {
  cancel: 'CANCEL',
  update: 'UPDATE',
}

export default class SelectionModal extends PureComponent {
  static defaultProps = {
    size: 'tiny',
  }

  state = {
    selected: [],
  }

  componentDidMount() {
    const { selected } = this.props
    if (selected) {
      this.setState({ initialSelected: [...selected], selected: [...selected] })
    }
  }

  handleSelect = async (id) => {
    const { selected } = this.state

    const idx = selected.indexOf(id)
    if (idx !== -1) {
      selected.splice(idx, 1)
    } else {
      selected.push(id)
    }

    await this.setState({ selected: [...selected] })
  }

  async beforeClose(action) {
    const { onClose } = this.props
    const { selected, initialSelected } = this.state

    if (action === ACTION.cancel) {
      await this.setState({ selected: initialSelected })
    }

    if (onClose) {
      onClose(action, selected)
    }
  }

  render() {
    const {
      props: { open, as, header, size },
      state: { selected },
    } = this

    const ContentType = as
    return (
      <Modal
        className={style.SelectionModal}
        onClose={() => false}
        open={open}
        size={size}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <ContentType
            selected={selected}
            onSelect={(id) => this.handleSelect(id)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content="Cancel"
            onClick={() => this.beforeClose(ACTION.cancel)}
            data-test="selection-modal-cancel-button"
          />
          <Button
            primary
            content="Update"
            onClick={() => this.beforeClose(ACTION.update)}
            data-test="selection-modal-update-button"
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
