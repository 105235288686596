export default function (minDate) {
  const minimumDate = (minDate instanceof Date && minDate) || new Date(minDate)

  if (isNaN(minimumDate)) {
    throw new Error(
      JSON.stringify({
        id: 'min_date_error',
        defaultMessage: 'minDate should be a valid date',
      }),
    )
  }

  return (value) => {
    if (!value) return

    const date = (value instanceof Date && value) || new Date(value)

    if (isNaN(date)) {
      throw new Error(
        JSON.stringify({ id: 'reformulate.validate_date_invalid' }),
      )
    }

    if (date < minimumDate) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_min_date',
          description: { date: minimumDate.toString() },
        }),
      )
    }
  }
}
