import React, { useState, useEffect } from 'react'
import { IMaskInput } from 'react-imask'
import classnames from 'classnames'

import style from '../style.css'
import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'

function DateField({
  label,
  name,
  placeholder,
  showErrors = true,
  validate,
  localValidator,
  className,
  ...restProps
}) {
  const { getData, updateField, getFormattedError, addValidator } = useForm()
  const [validateAdded, setValidateAdded] = useState()
  const error = getFormattedError(name)

  useEffect(() => {
    if (validate) {
      addValidator(name, localValidator)
      setValidateAdded(true)
    }
  }, [validateAdded])

  function handleChange(value) {
    const error = localValidator(value, { getData })

    updateField(name, { value, error })
  }

  return (
    <div className={classnames(style.formField, className)}>
      {label && (
        <label
          className={classnames(style.formFieldLabel, {
            [style.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <IMaskInput
        className={classnames(style.formFieldInput, {
          [style.formFieldInputError]: error,
        })}
        mask="00/00/0000"
        value={getData(name)}
        onAccept={handleChange}
        placeholder={placeholder}
        data-test={`${name}-input`}
        {...restProps}
      />

      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={style.formFieldErrorMessage}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export default withValidator()(DateField)
