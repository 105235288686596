import React from 'react'
import classnames from 'classnames'

import { HeaderDropdown } from './HeaderDropdown.jsx'
import style from './Header.css'

export function Header({
  className,
  logo,
  dropdownItems,
  logged,
  me,
  onLinkClick,
  children,
}) {
  const onLogoClick = (event) => {
    event.preventDefault()
    onLinkClick(logo.url)
  }

  return (
    <div className={classnames(style.root, className)}>
      <a href={logo.url} className={style.logo} onClick={onLogoClick}>
        <img alt="logo" src={logo.image} />
      </a>

      <div className={style.marginRight}>
        {children || (
          <HeaderDropdown
            logged={logged}
            me={me}
            dropdownItems={dropdownItems}
            onLinkClick={onLinkClick}
          />
        )}
      </div>
    </div>
  )
}
