import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { List, Button } from 'semantic-ui-react'

import SelectionModal, { ACTION } from '../SelectionModal/SelectionModal.jsx'
import style from './ListField.css'

export default class ListField extends PureComponent {
  static defaultProps = {
    editable: false,
    onAdd: null,
  }

  state = {
    addModalVisible: false,
  }

  handleModalVisibility = () => {
    this.setState({
      addModalVisible: true,
    })
  }

  handleModalResult = (action, selected) => {
    const { onSave } = this.props
    if (action === ACTION.update) {
      onSave(selected)
    }

    this.setState({
      addModalVisible: false,
    })
  }

  render() {
    const {
      className,
      label,
      name,
      editable,
      as,
      addAs,
      modalHeader,
      size,
      data,
    } = this.props
    const { addModalVisible } = this.state
    const { handleModalResult } = this

    const value = data || null
    const ElemType = as || 'span'

    return (
      <div className={classnames(style.container, className)}>
        {label && <label htmlFor={name}>{label}</label>}

        {addModalVisible && (
          <SelectionModal
            size={size}
            // eslint-disable-next-line react/jsx-no-bind
            onClose={handleModalResult.bind(this)}
            selected={value}
            open={addModalVisible}
            as={addAs}
            header={modalHeader}
          />
        )}

        <div className={classnames(style.buttonsContainer)}>
          {editable && addAs && (
            <Button
              icon="edit"
              content="Edit"
              data-test="list-field-edit-button"
              onClick={this.handleModalVisibility}
            />
          )}
        </div>

        <List divided data-test={`${name}-list`}>
          {value &&
            value.map((item, i) => (
              <List.Item
                key={i}
                className={classnames(style.unselectable, style.clearBoth)}
              >
                <List.Content>
                  {(as && <ElemType _id={item} key={item} />) || (
                    <ElemType>{item}</ElemType>
                  )}
                </List.Content>
              </List.Item>
            ))}
        </List>
      </div>
    )
  }
}
