import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import classnames from 'classnames'

import style from './BaseUploadInput.css'

const BaseUploadInput = (
  {
    accept = 'image/*',
    className,
    imgExtension = ['.jpg', '.jpeg', '.png'],
    maxFileSize = 5242880,
    name = '',
    onChange,
    onError,
    placeholder,
    src,
    styleAttributeContainer,
    styleAttributeSubContainer,
    uploadCallback = () => {},
  },
  ref,
) => {
  const inputElement = React.createRef()
  const [file, setFile] = useState(src)
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    uploadCallback(showSpinner)
  }, [showSpinner])

  useImperativeHandle(ref, () => ({
    openDialog: () => {
      inputElement.current.click()
    },
    removeImage: () => {
      setFile(null)
    },
  }))

  useEffect(() => {
    setFile(src)
  }, [src])

  const hasExtension = (fileName) => {
    const pattern = `(${imgExtension.join('|').replace(/\./g, '\\.')})$`

    return new RegExp(pattern, 'i').test(fileName)
  }

  const handleChange = (event) => {
    const { files } = event.target

    try {
      if (files.length < 1) {
        const errorMessage = 'No file added'
        throw new Error(errorMessage)
      }

      const file = files[0]
      // Check for file extension
      if (!hasExtension(file.name)) {
        const errorMessage = 'Invalid extension'
        throw new Error(errorMessage)
      }

      // Check for file size
      if (file.size > maxFileSize) {
        const errorMessage = 'Invalid file size'
        throw new Error(errorMessage)
      }

      // Read the image via FileReader API and save image result in state.
      const reader = new FileReader()
      reader.onload = (() => async (event) => {
        // Add the file name to the data URL
        const dataURL = event.target.result.replace(
          ';base64',
          `;name=${file.name};base64`,
        )

        setFile(dataURL)
        setShowSpinner(true)
        await onChange(file)
        setShowSpinner(false)
      })(file)

      reader.readAsDataURL(file)
    } catch (error) {
      setShowSpinner(false)
      onError(error.message)
    }
  }

  const placeholderImageStyle = {
    style: {
      backgroundImage: placeholder ? `url(${placeholder})` : 'inherit',
    },
  }

  return (
    <div
      className={classnames(className || style.container)}
      {...styleAttributeContainer}
    >
      <div
        className={style.uploadContainer}
        {...{ ...placeholderImageStyle, ...styleAttributeSubContainer }}
      >
        <input
          ref={inputElement}
          type="file"
          className={style.uploadInput}
          name={name}
          accept={accept}
          onChange={handleChange}
          data-test={`${name}-input`}
        />
        {file && <img src={file} className={style.image} />}
      </div>
    </div>
  )
}

export default forwardRef(BaseUploadInput)
