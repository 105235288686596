import React, { useContext, useState } from 'react'

const InfiniteScrollContext = React.createContext()

export const InfiniteScrollListProvider = (props) => {
  const [scrollPositions, updateScrollPosition] = useState({})

  const setScrollTop = (name, value) => {
    updateScrollPosition({
      ...scrollPositions,
      [name]: value,
    })
  }

  const getScrollPosition = (name) => scrollPositions[name]

  return (
    <InfiniteScrollContext.Provider
      value={{ getScrollPosition, setScrollTop }}
      {...props}
    />
  )
}

export function useInfiniteScroll(saveScrollPosition) {
  const context = useContext(InfiniteScrollContext)

  if (!saveScrollPosition) return {}

  if (!context) {
    throw new Error(
      'useInfiniteScroll must be used within a InfiniteScrollListProvider.',
    )
  }
  return { ...context }
}
