import React from 'react'
import { Icon } from 'semantic-ui-react'

import style from './Loader.css'

export function Loader({ children }) {
  return (
    <div className={style.container}>
      <Icon name="spinner" loading size="big" />
      {children && <div className={style.text}>{children}</div>}
    </div>
  )
}
