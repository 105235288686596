import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import style from './Modal.css'
import cross from './cross.svg'

const ESCAPE_KEY_CODE = 27

export const Modal = ({
  title,
  children,
  open = false,
  showClose = true,
  onCloseClick = () => {},
  duration,
  className,
  testId,
}) => {
  const [openModal, setOpenModal] = useState(open)

  useEffect(() => {
    const handleKey = ({ keyCode }) => {
      switch (keyCode) {
        case ESCAPE_KEY_CODE: {
          openModal && onCloseClick()
          break
        }
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => window.removeEventListener('keydown', handleKey)
  })

  useEffect(() => {
    setOpenModal(open)

    let timeout
    if (duration) {
      timeout = setTimeout(() => setOpenModal(false), duration)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [open])

  if (!openModal) return null

  return (
    <div className={style.container}>
      <div className={style.modal} data-test={testId}>
        {showClose && (
          <img
            className={style.cross}
            src={cross}
            alt="cross-icon"
            onClick={() => onCloseClick()}
          />
        )}
        {title && <div className={style.title}>{title}</div>}
        <div className={classnames(style.content, className)}>{children}</div>
      </div>
      <div className={style.overlay} onClick={() => onCloseClick()}></div>
    </div>
  )
}
