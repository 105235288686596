import React from 'react'
import classnames from 'classnames'
import { Image } from 'semantic-ui-react'

import style from './VerticalTabMenuItemCard.css'
import { RightIcon } from '../../icons/light.jsx'

export const VerticalTabMenuItemCard = ({
  selected,
  item,
  onClick,
  size,
  imageUrl,
  title,
  subTitle
}) => {
  const borderRadius = `${size / 6}px`
  const imageStyle = {
    width: size,
    height: size,
    lineHeight: `${size}px`,
    marginRight: `${size / 10}px`,
    borderRadius,
  }

  function handleClick(event) {
    event.preventDefault()
    onClick(item)
  }

  return (
    <div
      className={classnames(style.container, {
        [style.selected]: selected,
      })}
      onClick={handleClick}
    >
      <div>
        <div className={style.details}>
          {imageUrl &&
            <Image
              src={imageUrl}
              style={imageStyle}
              className={style.photo}
            />
          }
          <div className={style.texts}>
            <div style={{ fontSize: `${size * 0.3}px` }}>
              {title}
            </div>
            <div style={{ fontSize: `${size * 0.2}px` }}>
              {subTitle}
            </div>
          </div>
        </div>
      </div>
      <RightIcon size="lg" />
    </div>
  )
}
