import React, { useRef } from 'react'

import { useForm } from '../Form/FormContext'
import style from './VerificationCodeField.css'

const VerificationCodeField = ({ name, className, testId }) => {
  const digit1Ref = useRef(null)
  const digit2Ref = useRef(null)
  const digit3Ref = useRef(null)
  const digit4Ref = useRef(null)
  const digit5Ref = useRef(null)
  const digit6Ref = useRef(null)

  const { getData, updateField } = useForm()

  const getValue = (index) => {
    const inputValue = getData(name) && getData(name)[index]
    if (inputValue === '\0' || !inputValue) {
      return ''
    }

    return getData(name)[index]
  }

  const handleDigitChange = (event, nextRef = null) => {
    const value = event.target.value
    const isValidInput = event.target.validity.valid

    if (isValidInput) {
      const digitPosition = event.target.tabIndex

      const splittedFieldValue = getData(name).split('')
      // If no value, add a null character to occupy the position
      splittedFieldValue[digitPosition - 1] = value || '\0'

      const newFieldValue = splittedFieldValue.join('')
      updateField(name, { value: newFieldValue })

      value && nextRef && nextRef.current.focus()
    }
  }

  /**
   * Handles the 'key down' on inputs.
   * If no change occurs on input, we must handle the delete key.
   */
  const handleKeyDown = (event, inputRef, previousInputRef = null) => {
    const keyCode = event.keyCode
    const value = inputRef.current.value

    if (keyCode === 8 && !value) {
      // The Delete key was pressed, but actual input is empty > Move focus to previous input.
      previousInputRef && previousInputRef.current.focus()
    }
  }

  return (
    <div className={className} data-test={testId}>
      <input
        className={style.input}
        maxLength="1"
        onChange={(event) => handleDigitChange(event, digit2Ref)}
        onKeyDown={(event) => handleKeyDown(event, digit1Ref)}
        pattern="[0-9]{1}"
        ref={digit1Ref}
        type="text"
        tabIndex="1"
        value={getValue(0)}
      />
      <input
        className={style.input}
        maxLength="1"
        onChange={(event) => handleDigitChange(event, digit3Ref)}
        onKeyDown={(event) => handleKeyDown(event, digit2Ref, digit1Ref)}
        pattern="[0-9]{1}"
        ref={digit2Ref}
        type="text"
        tabIndex="2"
        value={getValue(1)}
      />
      <input
        className={style.input}
        maxLength="1"
        onChange={(event) => handleDigitChange(event, digit4Ref)}
        onKeyDown={(event) => handleKeyDown(event, digit3Ref, digit2Ref)}
        pattern="[0-9]{1}"
        ref={digit3Ref}
        type="text"
        tabIndex="3"
        value={getValue(2)}
      />
      <input
        className={style.input}
        maxLength="1"
        onChange={(event) => handleDigitChange(event, digit5Ref)}
        onKeyDown={(event) => handleKeyDown(event, digit4Ref, digit3Ref)}
        pattern="[0-9]{1}"
        ref={digit4Ref}
        type="text"
        tabIndex="4"
        value={getValue(3)}
      />
      <input
        className={style.input}
        maxLength="1"
        onChange={(event) => handleDigitChange(event, digit6Ref)}
        onKeyDown={(event) => handleKeyDown(event, digit5Ref, digit4Ref)}
        pattern="[0-9]{1}"
        ref={digit5Ref}
        type="text"
        tabIndex="5"
        value={getValue(4)}
      />
      <input
        className={style.input}
        maxLength="1"
        onChange={(event) => handleDigitChange(event)}
        onKeyDown={(event) => handleKeyDown(event, digit6Ref, digit5Ref)}
        pattern="[0-9]{1}"
        ref={digit6Ref}
        type="text"
        tabIndex="6"
        value={getValue(5)}
      />
    </div>
  )
}

export default VerificationCodeField
