import React, { useContext } from 'react'
import classnames from 'classnames'

import style from './Form.css'

export const FormContext = React.createContext({})

export function FormProvider({
  value,
  className,
  onChange,
  onSubmit,
  children,
}) {
  function handleSubmit(event) {
    event.preventDefault()
    onSubmit()
  }

  return (
    <FormContext.Provider value={value}>
      {value.isFormReady() && (
        <form
          className={classnames(style.form, className)}
          onChange={onChange}
          onSubmit={handleSubmit}
        >
          {children}
        </form>
      )}
    </FormContext.Provider>
  )
}

export function useForm() {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error('useForm must be used within a FormProvider')
  }
  return { ...context }
}
