export default function (chars = 5) {
  return (value) => {
    if (typeof value === 'string' && value.length > 0 && value.length < chars) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_min_length',
          description: { chars },
        }),
      )
    }
  }
}
