import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'
import sharedStyle from '../style.css'
import style from './ColorField.css'

const ColorField = ({
  className = '',
  label,
  localValidator,
  name,
  onChange,
  placeholder = '',
  required,
  showErrors = true,
  validate,
}) => {
  const { getData, updateField, getFormattedError, addValidator } = useForm()
  const [validateAdded, setValidateAdded] = useState()
  const error = getFormattedError(name)
  const colorValue = getData(name) || ''

  useEffect(() => {
    if (validate && !validateAdded) {
      addValidator(name, localValidator)
      setValidateAdded(true)
    }
  }, [validateAdded])

  function handleChange({ target: { value } }) {
    const error = localValidator(value, { getData })
    const hexValue = value.replace(/[^0-9A-F]$/i, '')
    updateField(name, { value: hexValue, error })
    if (onChange) {
      onChange(hexValue)
    }
  }

  /**
   * Check if the value is a valid hex color.
   */
  const isHexColor = (value) => /[0-9A-F]{6}$/i.test(value)

  return (
    <div className={classnames(sharedStyle.formField, className)}>
      {label && (
        <label
          className={classnames(sharedStyle.formFieldLabel, {
            [sharedStyle.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <div className={style.inputContainer}>
        <div
          className={style.colorBox}
          style={{
            border: isHexColor(colorValue) ? 'none' : 'dashed 1px',
            background: isHexColor(colorValue) ? `#${colorValue}` : 'none',
          }}
        />
        <span className={style.symbol}>#</span>
        <input
          className={classnames(sharedStyle.formFieldInput, style.inputField, {
            [sharedStyle.formFieldInputError]: error,
          })}
          data-test={`${name}-input`}
          maxLength={6}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          value={colorValue}
        />
      </div>

      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={style.formFieldErrorMessage}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export default withValidator()(ColorField)
