import React from 'react'
import InputField from '../../forms/InputField/InputField.jsx'
import { withValidator } from '../../forms/validators/index'
import validate from '../../forms/validators/index'
import { EnvelopeIcon } from '../../icons/light.jsx'

import style from './PasswordStepForm.css'

const EmailStep = ({
  validators,
  name = 'email',
  placeholder = 'Your Email',
  inputType = 'underline',
}) => (
  <InputField
    inputClassName={style.input}
    name={name}
    placeholder={placeholder}
    validate={
      validators || validate.combine(validate.isEmail(), validate.notEmpty())
    }
    inputType={inputType}
    suffix={
      <div className={style.inputIcon}>
        <EnvelopeIcon className={style.envelopIcon} size="lg" />
      </div>
    }
  />
)

export default withValidator()(EmailStep)
