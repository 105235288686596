import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Dropdown, Icon } from 'semantic-ui-react'

import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'
import { Button } from '../../base/Button/Button.jsx'
import { convertToKebabCase } from '../../../utils/index.js'

import sharedStyle from '../style.css'
import style from './MultipleSelectField.css'

let validateAdded = false

/**
 * Finds the selected option by it's value
 */
const findDropdownItemByValue = (value, options) =>
  options.find((option) => option.value === value)

/**
 * Returns a list of items available for selection.
 */
const getUnselectedItems = (allItems, selectedItems) => {
  // No items selected, we return all available items
  if (!selectedItems || selectedItems.length === 0 || allItems.length === 0) {
    return allItems
  }

  const selectedItemsMapped = (selectedItems || []).reduce(
    (accItems, value) => {
      const item = findDropdownItemByValue(value, allItems)
      return { ...accItems, [item.value]: item }
    },
    {},
  )

  return allItems.reduce((accOptions, option) => {
    if (!selectedItemsMapped[option.value]) {
      accOptions.push(option)
    }
    return accOptions
  }, [])
}

function MultipleSelectField({
  className,
  disabled,
  label,
  name,
  required,
  placeholder,
  onChange,
  options = [],
  showErrors = true,
  localValidator,
  validate,
  allowAdditions,
  onAddOption,
  testId = 'multiple-select-field',
}) {
  const {
    addValidator,
    getData,
    getFormattedError,
    updateField,
    updateError,
  } = useForm()

  const [localOptions, setLocalOptions] = useState(options)
  const [selectedOption, setSelectedOption] = useState({ value: '', text: '' })

  useEffect(() => {
    if (localOptions.length === 0) {
      setLocalOptions(options)
    }
  }, [options])

  // Check field errors
  const error = getFormattedError(name)

  // Add the validators
  if (validate && !validateAdded) {
    addValidator(name, localValidator)
    validateAdded = true
  }

  // Map selected and unselected items
  const selectedItems = getData(name)
  const unselectedItems = getUnselectedItems(localOptions, selectedItems)

  // Check for field errors when selecting new items
  useEffect(() => {
    const fieldError = localValidator(selectedItems, { getData })
    updateError(name, fieldError)
  }, [selectedItems])

  const handleAddNewOption = async (_, { value }) => {
    const newOption = await onAddOption(value)
    setLocalOptions((options) => [...options, newOption])
    setSelectedOption(newOption)
  }

  const handleChange = (_, { value, options }) => {
    const itemObject = findDropdownItemByValue(value, options)
    if (itemObject) {
      setSelectedOption({ value, text: itemObject.text })
    }

    if (onChange) {
      onChange(value)
    }
  }

  const handleAdd = () => {
    updateField(name, { value: [...selectedItems, selectedOption.value] })
    setSelectedOption({ value: '', text: '' })
  }

  /**
   * Handles Remove icon (label)
   */
  const handleRemove = (index) => {
    const newFieldValues = selectedItems.slice()
    newFieldValues.splice(index, 1)
    updateField(name, { value: newFieldValues })
  }

  return (
    <div
      className={classnames(
        sharedStyle.formField,
        style.multiSelectField,
        className,
      )}
    >
      {label && (
        <label
          className={classnames(sharedStyle.formFieldLabel, {
            [sharedStyle.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <div className={style.container}>
        <Dropdown
          selection
          search
          className={style.dropdown}
          disabled={
            disabled || (!allowAdditions && unselectedItems.length === 0)
          }
          required={required}
          placeholder={placeholder}
          value={selectedOption.value}
          options={unselectedItems}
          allowAdditions={allowAdditions}
          additionLabel="(New) "
          noResultsMessage={`No results found${
            allowAdditions && ', start typing to add a new option'
          }.`}
          onAddItem={handleAddNewOption}
          onChange={handleChange}
          data-test={testId}
        />
        <Button
          className={style.addButton}
          data-test={`button-${testId}`}
          disabled={selectedOption.text === ''}
          onClick={handleAdd}
        >
          Add
        </Button>
        {localOptions.length > 0 &&
          selectedItems &&
          selectedItems.map((value, index) => {
            const item = findDropdownItemByValue(value, localOptions)
            return (
              <div
                data-test={`label-${convertToKebabCase(item.text)}`}
                key={item.value}
                className={style.optionItem}
              >
                <Icon
                  className={style.deleteIcon}
                  name="delete"
                  onClick={() => handleRemove(index)}
                />{' '}
                {item.text}
              </div>
            )
          })}
      </div>

      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={sharedStyle.formFieldErrorMessage}
        >
          {getFormattedError(name)}
        </div>
      )}
    </div>
  )
}

export default withValidator()(MultipleSelectField)
