export default function (maxDate) {
  const maximumDate = (maxDate instanceof Date && maxDate) || new Date(maxDate)

  if (isNaN(maximumDate)) {
    throw new Error('maxDate should be a valid date')
  }

  return (value) => {
    if (!value) return

    const date = (value instanceof Date && value) || new Date(value)

    if (isNaN(date)) {
      throw new Error(
        JSON.stringify({ id: 'reformulate.validate_date_invalid' }),
      )
    }

    if (date > maximumDate) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_max_date',
          description: { date: maximumDate.toString() },
        }),
      )
    }
  }
}
