import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'
import sharedStyle from '../style.css'
import style from './PercentageField.css'

function PercentageField({
  placeholder = '',
  className = '',
  onChange,
  symbol = '%',
  validate,
  localValidator,
  showErrors = true,
  label,
  name,
  disabled = false,
}) {
  const { getData, updateField, getFormattedError, addValidator } = useForm()
  const [validateAdded, setValidateAdded] = useState()
  const error = getFormattedError(name)

  useEffect(() => {
    if (validate && !validateAdded) {
      addValidator(name, localValidator)
      setValidateAdded(true)
    }
  }, [validateAdded])

  function handleChange({ target: { value } }) {
    const error = localValidator(value, { getData })
    const formattedValue = value ? parseInt(value) : value

    updateField(name, { value: formattedValue, error })
    if (onChange) {
      onChange(formattedValue)
    }
  }

  const hasValue = () => getData(name) || getData(name) === 0

  return (
    <div className={classnames(sharedStyle.formField, className)}>
      {label && (
        <label
          className={classnames(sharedStyle.formFieldLabel, {
            [sharedStyle.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <div className={style.inputContainer}>
        {hasValue() && <span className={style.symbol}>{symbol}</span>}
        <input
          className={classnames(sharedStyle.formFieldInput, style.inputField, {
            [sharedStyle.formFieldInputError]: error,
          })}
          disabled={disabled}
          type="number"
          step="1"
          placeholder={placeholder}
          value={hasValue() ? getData(name) : ''}
          onChange={handleChange}
          data-test={`${name}-input`}
        />
      </div>

      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={sharedStyle.formFieldErrorMessage}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export default withValidator()(PercentageField)
