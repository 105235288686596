import React from 'react'

import { Card } from '../../Card/Card.jsx'
import style from './ListEmpty.css'

export const ListEmpty = ({ message }) => {
  return (
    <Card data-test="EmptyCard" emptyCard>
      <b>{message}</b> If you feel this is an error, you may try to{' '}
      <button
        type="button"
        onClick={() => location.reload()}
        className={style.refreshLink}
      >
        refresh page
      </button>{' '}
      &mdash; this may be a momentary issue.
    </Card>
  )
}
