import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Form } from 'semantic-ui-react'

import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'

import style from '../style.css'

function SelectField({
  className = '',
  label,
  type,
  name,
  required,
  placeholder,
  validate,
  displayValueAsText = false,
  showErrors = true,
  options = [],
  disabled = false,
  localValidator,
  value: defaultValue = '',
  onChange,
  loading,
  testId,
}) {
  // Semantic UI Form.Select triggers an onChange when the component is mounted,
  // this behavior breaks the form dirty state.
  // To avoid that, the isInitialOnChangeTriggered flag will be set to true
  // after the initial onChange is triggered.
  const [isInitialOnChangeTriggered, setInitialOnChangeTriggered] = useState(
    false,
  )

  const { getData, updateField, getFormattedError, addValidator } = useForm()
  const [value, setValue] = useState(null)
  const [validateAdded, setValidateAdded] = useState()
  const error = getFormattedError(name)

  useEffect(() => {
    if (validate) {
      addValidator(name, localValidator)
      setValidateAdded(true)
    }
  }, [validateAdded])

  useEffect(() => {
    handleChange(defaultValue)
  }, [defaultValue])

  function handleChange(value) {
    if (!isInitialOnChangeTriggered) {
      setInitialOnChangeTriggered(true)

      if (!defaultValue) return
    }

    onChange && onChange(value)
    const error = localValidator(value, { getData })

    updateField(name, { value, error })

    if (displayValueAsText) {
      setValue(value)
    }
  }

  function getValue(name) {
    const value = getData(name)
    if (value === null || value === undefined) {
      return defaultValue
    }
    return value
  }

  return (
    <div className={classnames(style.formField, className)} {...{ required }}>
      {label && (
        <label
          className={classnames(style.formFieldLabel, {
            [style.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <Form.Select
        fluid
        value={getValue(name)}
        text={displayValueAsText ? value || getData(name) : null}
        onChange={(_, data) => handleChange(data.value)}
        data-test={testId || `${name}-dropdown`}
        {...{
          type,
          placeholder,
          disabled,
          required,
          options,
          loading,
        }}
      />

      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={style.formFieldErrorMessage}
        >
          {getFormattedError(name)}
        </div>
      )}
    </div>
  )
}
export default withValidator()(SelectField)
