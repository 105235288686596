import React from 'react'
import DOMPurify from 'dompurify'

const defaultOptions = {
  ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a'],
  ALLOWED_ATTR: {
    a: ['href'],
  },
}

export function SanitizedHTML({ text, options }) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(text, {...defaultOptions, ...options}),
      }}
    />
  )
}
