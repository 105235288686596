export const en = {
  'reformulate.error_header': 'Please check all the fields again.',
  'reformulate.validate_email_invalid':
    'This field should have a valid email address.',
  'reformulate.validate_phone_invalid':
    'This field should have a valid international telephone number represented without spaces.',
  'reformulate.validate_website_invalid':
    'This field should have a valid website url.',
  'reformulate.validate_number_invalid':
    'This field should have a valid number.',
  'reformulate.validate_date_invalid': 'Invalid date',
  'reformulate.validate_max_date':
    'This field should have a date before {date}',
  'reformulate.validate_min_date': 'This field should have a date after {date}',
  'reformulate.validate_max_length':
    'This field should have {chars} or less characters',
  'reformulate.validate_min_length':
    'This field should have at least {chars} characters',
  'reformulate.validate_min_value':
    'Please enter a number greater than or equal to {min}',
  'reformulate.validate_max_value':
    'Please enter a number less than or equal to {max}',
  'reformulate.validate_not_empty': 'This field should not be empty',
  'reformulate.validate_selected_ensure':
    'This field should have options selected',
  'reformulate.validate_selected_min':
    'This field should have at least {min} options selected',
  'reformulate.validate_selected_max':
    'This field should have a maximum of {max} options selected',
  'reformulate.date_wrong_format': 'The date is in the wrong format',
  'auth.invalid_email': 'Invalid email',
  'auth.invalid_password': 'Invalid password',
  'auth.invalid_type': 'You do not have access to this platform',
  'auth.unable_to_identify_user': 'Unable to identify user',
  'auth.invalid_recovery_token': 'Invalid recovery token',
  'auth.passwords_doesnt_match': 'Passwords do not match',
  'auth.unknown_error': 'Unknown error',
}
