import React from 'react'
import { Icon } from 'semantic-ui-react'

import { Modal } from '../../base/Modal/Modal.jsx'

export function ErrorModal({
  open,
  message = 'Something went wrong. Please try again later.',
  onCloseClick,
  testId = 'error-modal',
}) {
  return (
    <Modal open={open} onCloseClick={onCloseClick} testId={testId}>
      <Icon name="exclamation circle" color="red" /> {message}
    </Modal>
  )
}
