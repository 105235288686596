import React from 'react'

export const withValidator = () => (Component) =>
  class extends React.Component {
    displayName = 'WrappedComponent'

    validator = (value, context) => {
      const { validate } = this.props

      try {
        if (validate) {
          validate(value, context.getData())
        }
      } catch (error) {
        return error
      }
    }

    render() {
      return (
        <Component {...{ ...this.props, localValidator: this.validator }} />
      )
    }
  }
