import React from 'react'
import { Icon } from 'semantic-ui-react'

import { Button } from '../../base/Button/Button.jsx'
import { Modal } from '../../base/Modal/Modal.jsx'
import style from './YesCancelModal.css'

export const YesCancelModal = ({
  open,
  message = 'Are you sure?',
  onCloseClick,
  onYesClick,
  title,
  testId = 'yes-cancel-modal',
}) => (
  <Modal
    open={open}
    onCloseClick={onCloseClick}
    title={title}
    className={style.modal}
    testId={testId}
  >
    <div>
      <Icon name="question circle" color="black" /> {message}
    </div>
    <div className={style.modalButtons}>
      <Button onClick={onYesClick}>Yes</Button>
      <Button
        className={style.cancelButton}
        onClick={onCloseClick}
        variant="secondary"
      >
        Cancel
      </Button>
    </div>
  </Modal>
)
