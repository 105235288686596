import React from 'react'
import style from './InputField.css'
import { withValidator } from '../../../forms/validators/index'
import InputField from '../../../forms/InputField/InputField.jsx'

export const IconPosition = {
  left: 'left',
  right: 'right',
}

function IconInputField(props) {
  const { icon, showPosition = IconPosition.right, name } = props
  let suffix = ''

  if (icon) {
    suffix = (
      <div>
        <img
          className={style.envelopImg}
          alt={`icon-${name}`}
          src={icon}
          color="#594a9b"
        />
      </div>
    )
  }

  return (
    <InputField
      {...props}
      suffix={suffix}
      suffixclassName={
        showPosition === IconPosition.left ? style.suffixPosition : ''
      }
    />
  )
}

export default withValidator()(IconInputField)
