import React from 'react'
import classnames from 'classnames'

import style from './Sidebar.css'

export function Sidebar({ className, items = [], onItemClick }) {
  const handleItemClick = (event, url) => {
    event.preventDefault()
    onItemClick(url)
  }

  return (
    <div className={classnames(style.sidebar, className)}>
      {items.reduce((menuItems, item) => {
        if (
          Object.prototype.hasOwnProperty.call(item, 'visible') &&
          item.visible === false
        ) {
          return menuItems
        }

        return [
          ...menuItems,
          <a
            href={item.url}
            key={item.title.replace(/\s/, '_')}
            className={classnames({ [style.active]: item.active })}
            title={item.title}
            onClick={(event) => handleItemClick(event, item.url)}
            onKeyDown={(event) => handleItemClick(event, item.url)}
          >
            <img src={item.icon} alt={item.title} />
          </a>,
        ]
      }, [])}
    </div>
  )
}
