import React from 'react'
import { Grid } from 'semantic-ui-react'

import VerificationCodeField from '../../forms/VerificationCodeField/VerificationCodeField.js'

import style from './PasswordStepForm.css'

export const ResetCodeStep = ({ resendCode }) => (
  <>
    <Grid.Row>
      <Grid.Column>
        <VerificationCodeField
          name="token"
          className={style.verificationCodeInput}
          testId="verification-code-input"
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <div
          className={style.resendCode}
          onClick={() => resendCode()}
          data-test="link-resend-code"
        >
          RESEND CODE
        </div>
      </Grid.Column>
    </Grid.Row>
  </>
)

export default ResetCodeStep
