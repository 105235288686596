import React from 'react'
import InputField from '../../../forms/InputField/InputField.jsx'
import { withValidator } from '../../../forms/validators/index.js'
import style from './PricingInputField.css'

function PricingInputField(props) {
  const { classSymbol } = props
  return (
    <InputField type="number" className={`${style.pricingInput} ${style[classSymbol]}`} {...props} />
  )
}

export default withValidator()(PricingInputField)