import React from 'react'
import { SelectField } from '../../../forms/index'
import { useForm } from '../../../forms/Form/FormContext'
import { withValidator } from '../../../forms/validators/index'
const DEFAULT_TYPE_OPTIONS = [
  { text: 'Mobile', value: 'mobile' },
  { text: 'Home', value: 'home' },
  { text: 'Work', value: 'work' },
]

function PhoneNumberTypeSelectField(props) {
  const { options = DEFAULT_TYPE_OPTIONS, value: defaultValue, name } = props
  const { getData } = useForm()

  function getValue(name) {
    const value = getData(name)
    if (!value) {
      return !defaultValue &&
        options?.some((option) => option.value === 'mobile')
        ? 'mobile'
        : defaultValue
    }
    return value
  }

  return <SelectField {...props} options={options} value={getValue(name)} />
}

export default withValidator()(PhoneNumberTypeSelectField)
