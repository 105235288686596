import React from 'react'
import { withValidator } from '../../../forms/validators/index'
import IconInputField from '../IconInputField/IconInputField.jsx'
import LockIconWhite from './lock-icon-white.svg'
import LockIconGreen from './lock-icon-green.svg'
import LockIconRed from './lock-icon-red.svg'
import { useForm } from '../../../forms/Form/FormContext'

function PasswordInputField(props) {
  const { validate, name } = props
  const { getFormattedError, getData } = useForm()

  const isValid = !getFormattedError(name)
  const isFieldDirty = React.useRef(false)
  const needsValidation = !!validate

  if (getData(name)?.length > 0) {
    isFieldDirty.current = true
  }

  const iconVariant = () => {
    if (isFieldDirty.current && needsValidation && !isValid) {
      return LockIconRed
    } else if (isFieldDirty.current && needsValidation && isValid) {
      return LockIconGreen
    } else {
      return LockIconWhite
    }
  }

  return <IconInputField {...props} icon={iconVariant()} type="password" />
}

export default withValidator()(PasswordInputField)
