import React from 'react'
import classnames from 'classnames'

import { Card } from '../Card/Card.jsx'
import style from './ListRow.css'

/**
 * List Row component.
 */
export const ListRow = ({
  as,
  bordered = true,
  children,
  className = '',
  to,
  ...props
}) => (
  <Card
    {...props}
    as={as}
    className={classnames(style.listRow, className)}
    to={to}
    bordered={bordered}
  >
    {children}
  </Card>
)
