const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi

export default function () {
  return (value) => {
    if (!value) return
    if (typeof value === 'string' && !value.match(EMAIL)) {
      throw new Error(
        JSON.stringify({ id: 'reformulate.validate_email_invalid' }),
      )
    }
  }
}
