import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import { Icon } from 'semantic-ui-react'

import { BaseUploadInput } from '../../../forms/index'
import { useForm } from '../../../forms/Form/FormContext'
import { withValidator } from '../../../forms/validators/index'
import style from './ProfileImageUploadField.css'
import sharedStyle from '../style.css'
import avatarIcon from './avatar-icon.svg'

function ProfileImageUploadField({
  className,
  disabled,
  classNameInput,
  image,
  label,
  localValidator,
  name,
  required,
  showErrors = true,
  uploadFn,
  plusIconBottom = false,
}) {
  if (!uploadFn) {
    throw new Error('The "uploadFn" prop is not set.')
  }

  const childRef = useRef()
  const { getData, getFormattedError, updateField } = useForm()
  const error = getFormattedError(name)

  const [showSpinner, setShowSpinner] = useState(false)

  const handleSpinner = (enabled) => {
    setShowSpinner(enabled)
  }

  async function handleChange(file) {
    try {
      const value = await uploadFn(file)
      const error = localValidator(value, { getData, updateField })
      updateField(name, { value, error })
    } catch (error) {
      const errorMessage = 'Error while uploading the Image to the Server.'
      updateField(name, { error: errorMessage })
    }
  }

  function handleError(error) {
    updateField(name, { error })
    throw new Error(error)
  }

  return (
    <>
      {label && (
        <label
          className={classnames(sharedStyle.formFieldLabel, {
            [sharedStyle.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}{' '}
      <div
        className={classnames(className || style.formField)}
        {...{ required }}
      >
        <BaseUploadInput
          className={classNameInput}
          name={name}
          onChange={handleChange}
          onError={handleError}
          placeholder={avatarIcon}
          ref={childRef}
          src={image}
          uploadCallback={handleSpinner}
          {...{ disabled, required }}
        />
        <div
          className={classnames(style.uploadIcon, {
            [style.uploadIconBottom]: plusIconBottom,
          })}
          onClick={() => childRef.current.openDialog()}
        >
          {showSpinner ? (
            <Icon className={style.spinner} loading name="spinner" />
          ) : (
            '+'
          )}
        </div>

        {showErrors && error && (
          <div
            data-test={`${name}-error-message`}
            className={sharedStyle.formFieldErrorMessage}
          >
            {error}
          </div>
        )}
      </div>
    </>
  )
}

export default withValidator()(ProfileImageUploadField)
