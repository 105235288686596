import React from 'react'
import classnames from 'classnames'

import style from './NameInitials.css'

export const NameInitials = ({ firstName, lastName, size = 28, className }) => {
  const borderRadius = `${size / 2}px`

  return (
    <div
      className={classnames(style.initials, className)}
      style={{
        width: size,
        height: size,
        lineHeight: `${size}px`,
        fontSize: size / 3,
        borderRadius,
      }}
    >
      {firstName[0]}
      {lastName[0]}
    </div>
  )
}
