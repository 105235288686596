const PASSWORD_STRENGTH_REG_EX = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,12})',
)

export default function () {
  return (value) => {
    if (!PASSWORD_STRENGTH_REG_EX.test(value) || value.length > 12) {
      throw new Error(JSON.stringify({ id: 'auth.invalid_password' }))
    }
  }
}
