import notEmpty from './not-empty'
import minLength from './min-length'
import maxLength from './max-length'
import minValue from './min-value'
import maxValue from './max-value'
import minDate from './min-date'
import maxDate from './max-date'
import selected from './selected'
import isEmail from './is-email'
import isFormattedDate from './is-formatted-date'
import isPhone from './is-phone'
import isWebsite from './is-website'
import isNumber from './is-number'
import isPasswordStrong from './is-password-strong'
import isSamePassword from './is-same-password'

import combine from './combine'

export { withValidator } from './validate'

export default {
  notEmpty,
  minLength,
  maxLength,
  minValue,
  maxValue,
  minDate,
  maxDate,
  selected,
  isEmail,
  isFormattedDate,
  isPhone,
  isWebsite,
  isNumber,
  isPasswordStrong,
  isSamePassword,
  combine,
}
