import React from 'react'
import { Icon } from 'semantic-ui-react'

import { Modal } from '../../base/Modal/Modal.jsx'

export function SavingModal({
  open,
  message = "We're saving your data, please wait...",
  testId = 'saving-modal',
}) {
  return (
    <Modal open={open} showClose={false} testId={testId}>
      <Icon loading name="spinner" /> {message}
    </Modal>
  )
}
