export default function (max) {
  return (value) => {
    if (value > max) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_max_value',
          description: { max },
        }),
      )
    }
  }
}
