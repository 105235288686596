import React from 'react'
import classnames from 'classnames'
import { Image } from 'semantic-ui-react'

import style from './ProfilePhoto.css'

export function ProfilePhoto({ size, className, url, children, initials }) {
  const borderRadius = `${size / 2}px`
  const divStyle = {
    width: size,
    height: size,
    lineHeight: `${size}px`,
    borderRadius,
  }

  let content
  if (url) {
    content = <Image src={url} style={divStyle} />
  } else if (initials) {
    content = (
      <div className={style.initials} style={{ fontSize: `${size * 0.3}px` }}>
        {initials}
      </div>
    )
  } else {
    content = children
  }

  return (
    <div className={classnames(style.profilePhoto, className)} style={divStyle}>
      <div
        className={classnames(style.inner, style.inheritHeight)}
        style={{ borderRadius }}
      >
        {content}
      </div>
    </div>
  )
}
