import React from 'react'
import classnames from 'classnames'

import style from './List.css'

export const List = ({ children, className = '' }) => {
  return (
    <div id="List" className={classnames(style.list, className)}>
      {children}
    </div>
  )
}
