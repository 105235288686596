import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import { Loader } from 'semantic-ui-react'

import { BaseUploadInput } from '../../../forms/index'
import { useForm } from '../../../forms/Form/FormContext'
import { withValidator } from '../../../forms/validators/index'
import style from './FeaturedImageUploadField.css'
import sharedStyle from '../style.css'

function FeaturedImageUploadField({
  buttonRemoveText = 'REMOVE IMAGE',
  className,
  classNameLabel,
  disabled,
  image,
  label,
  localValidator,
  name,
  width = 80,
  height = 50,
  required,
  showErrors = true,
  uploadFn,
  removeFn,
}) {
  if (!uploadFn) {
    throw new Error('The "uploadFn" prop is not set.')
  }
  const [showLoader, setShowLoader] = useState(false)

  const childRef = useRef()
  const { getData, getFormattedError, updateField } = useForm()
  const error = getFormattedError(name)

  async function handleChange(file) {
    try {
      const value = await uploadFn(file)
      const error = localValidator(value, { getData, updateField })
      updateField(name, { value, error })
    } catch (error) {
      const errorMessage = 'Error while uploading the Image to the Server.'
      updateField(name, { error: errorMessage })
    }
  }

  function handleRemove(event) {
    event.stopPropagation()
    if (showLoader) return
    childRef.current.removeImage()
    if (removeFn) removeFn()
  }

  function handleError(error) {
    updateField(name, { error })
    throw new Error(error)
  }

  const styleAttributeContainer = {
    style: {
      width: `${width}px`,
      height: `${height}px`,
    },
  }

  const styleAttributeSubContainer = {
    style: {
      minWidth: `${width}px`,
      minHeight: `${height}px`,
    },
  }

  return (
    <>
      {label && (
        <label
          className={classnames(classNameLabel || sharedStyle.formFieldLabel, {
            [sharedStyle.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}{' '}
      <div
        className={classnames(className || style.container)}
        onClick={() => childRef.current.openDialog()}
        {...{ required }}
      >
        <BaseUploadInput
          className={style.input}
          name={name}
          onChange={handleChange}
          onError={handleError}
          ref={childRef}
          src={image}
          styleAttributeContainer={styleAttributeContainer}
          styleAttributeSubContainer={styleAttributeSubContainer}
          {...{ disabled, required }}
          uploadCallback={(value) => setShowLoader(value)}
        />
        <div
          className={showLoader ? style.disabled : style.controls}
          onClick={handleRemove}
        >
          <div className={style.removeImageLink}>{buttonRemoveText}</div>
        </div>

        {showLoader && (
          <Loader
            data-test={`${name}-image-loader`}
            active
            style={{
              position: 'absolute',
              left: '50px',
              top: '35px',
            }}
          />
        )}
      </div>
      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={sharedStyle.formFieldErrorMessage}
        >
          {error}
        </div>
      )}
    </>
  )
}

export default withValidator()(FeaturedImageUploadField)
