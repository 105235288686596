import React from 'react'
import { Grid } from 'semantic-ui-react'
import classnames from 'classnames'
import Form from '../../forms/Form/Form.jsx'
import SubmitButton from '../../forms/SubmitButton/SubmitButton.jsx'
import arrowright from './arrowright.svg'

import style from './PasswordStepForm.css'

const PasswordStepForm = ({
  currentStep,
  totalSteps,
  title,
  subTitle,
  onSubmit,
  intl,
  submitButtonText,
  nextButtonText,
  hasLastStepSubmit = true,
  size,
  errors = {},
  ...props
}) => {
  const { formatMessage } = intl

  return (
    <div className={style.formContainer}>
      <Form
        initialFormData={{}}
        onSubmit={onSubmit}
        intl={{ formatMessage }}
        errors={errors}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p className={style.stepNumber}>
                {currentStep}/{totalSteps}
              </p>
              <p className={classnames(style.text, style.description)}>
                {title}
              </p>
              <p className={classnames(style.text, style.detail)}>{subTitle}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{props.children}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {currentStep === totalSteps && hasLastStepSubmit ? (
                <div className={style.submitButtonContainer}>
                  <SubmitButton size={size || 'medium'}>
                    {submitButtonText || 'Save'}
                  </SubmitButton>
                </div>
              ) : (
                <div className={style.actions}>
                  <SubmitButton data-test={'next'} variant="basic">
                    <div className={style.buttonContentRoot}>
                      {nextButtonText || 'Next'}
                      <img src={arrowright} className={style.iconStyles} />
                    </div>
                  </SubmitButton>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  )
}

export default PasswordStepForm
