import React from 'react'
import { Icon } from 'semantic-ui-react'

import { Modal } from '../../base/Modal/Modal.jsx'

export function SavedModal({
  open,
  duration = 3000,
  message = 'Your data has been saved',
  testId = 'saved-modal',
}) {
  return (
    <Modal open={open} showClose={false} duration={duration} testId={testId}>
      <Icon name="check square" color="green" /> {message}
    </Modal>
  )
}
