import React, { useRef } from 'react'
import classnames from 'classnames'
import { Loader } from 'semantic-ui-react'

import { BaseUploadInput } from '../../../forms/index'
import { Button } from '../../../base/Button/Button.jsx'
import { useForm } from '../../../forms/Form/FormContext'
import { withValidator } from '../../../forms/validators/index'
import style from './LogoImageUploadField.css'
import sharedStyle from '../style.css'

function LogoImageUploadField({
  className,
  image,
  label,
  localValidator,
  name,
  showErrors = true,
  showLoader = true,
  uploadFn,
}) {
  if (!uploadFn) {
    throw new Error('The "uploadFn" prop is not set.')
  }

  const childRef = useRef()
  const { getData, getFormattedError, updateField } = useForm()
  const error = getFormattedError(name)

  async function handleChange(file) {
    try {
      const value = await uploadFn(file)
      const error = localValidator(value, { getData, updateField })
      updateField(name, { value, error })
    } catch (error) {
      const errorMessage = 'Error while uploading the Image to the Server.'
      updateField(name, { error: errorMessage })
    }
  }

  function handleError(error) {
    updateField(name, { error })
    throw new Error(error)
  }

  return (
    <>
      {label && (
        <label
          className={classnames(sharedStyle.formFieldLabel, {
            [sharedStyle.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}{' '}
      <div className={classnames(className || style.container)}>
        <BaseUploadInput
          className={style.input}
          name={name}
          onChange={handleChange}
          onError={handleError}
          ref={childRef}
          src={image}
        />
        <Button
          className={style.button}
          onClick={() => childRef.current.openDialog()}
        >
          Change logo
        </Button>
        {showLoader && (
          <Loader
            data-test={`${name}-image-loader`}
            style={{
              position: 'absolute',
              left: '130px',
              top: '30px',
            }}
            active
          />
        )}
        {showErrors && error && (
          <div
            data-test={`${name}-error-message`}
            className={sharedStyle.formFieldErrorMessage}
          >
            {error}
          </div>
        )}
      </div>
    </>
  )
}

export default withValidator()(LogoImageUploadField)
