import React, { useState } from 'react'
import delay from 'await-delay'

import { YesCancelModal } from './YesCancelModal'
import { SavingModal } from './SavingModal'
import { SavedModal } from './SavedModal'
import { ErrorModal } from './ErrorModal'

export function useSavingModals({
  confirmationMessage,
  savingMessage,
  savedMessage,
  errorMessage,
  callback, // function to be executed when the modal is shown
  onConfirm, // function to be executed after the user confirms the action
  onSuccess,
  onError,
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showSavingModal, setShowSavingModal] = useState(false)
  const [showSavedModal, setShowSavedModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [overrideErrorMessage, setOverrideErrorMessage] = useState(errorMessage)

  const [localVariables, setLocalVariables] = useState({})

  async function showModals(variables) {
    setShowSavingModal(true)

    const getErrorMessage = (error) => {
      // Is a GraphQL error?
      if (typeof error.graphQLErrors !== 'undefined') {
        return error.graphQLErrors[0].message
      }

      return error
    }

    let result
    try {
      if (onConfirm) {
        result = await onConfirm(variables)
      } else {
        result = await callback(variables)
      }
      await delay(1000)
      setShowSavingModal(false)

      setShowSavedModal(true)
      await delay(2000)
      setShowSavedModal(false)

      onSuccess && onSuccess(result)
    } catch (error) {
      if (onError) {
        const message = await onError(getErrorMessage(error))
        if (typeof message === 'string') {
          setOverrideErrorMessage(message)
        }
      }

      setShowSavingModal(false)
      setShowErrorModal(true)
    }
  }

  const modals = (
    <>
      {onConfirm && (
        <YesCancelModal
          open={showConfirmationModal}
          message={confirmationMessage}
          onCloseClick={() => {
            setShowConfirmationModal(false)
          }}
          onYesClick={async () => {
            setShowConfirmationModal(false)
            showModals(localVariables)
          }}
        />
      )}

      <SavingModal message={savingMessage} open={showSavingModal} />
      <SavedModal
        open={showSavedModal}
        message={savedMessage}
        onClose={() => setShowSavedModal(false)}
      />
      <ErrorModal
        open={showErrorModal}
        message={overrideErrorMessage}
        onCloseClick={() => {
          setShowSavingModal(false)
          setShowErrorModal(false)
        }}
      />
    </>
  )

  return [
    modals,
    {
      showConfirmationModal: (variables) => {
        setLocalVariables(variables)
        setShowConfirmationModal(true)
      },
      showSavingModal: setShowSavingModal,
      showSavedModal: setShowSavedModal,
      showErrorModal: setShowErrorModal,
      showModals,
    },
  ]
}
