const DEFAULT_OPTIONS = {
  forceString: false,
  forceArray: false,
}

export default function ({ forceString, forceArray } = DEFAULT_OPTIONS) {
  return (value) => {
    const isEmptyString =
      (forceString || typeof value === 'string') &&
      !value.trim().replace(/ /g, '')
    const isEmptyArray = forceArray && value.length === 0

    if (isEmptyString || isEmptyArray) {
      throw new Error(JSON.stringify({ id: 'reformulate.validate_not_empty' }))
    }
  }
}
