export default function (min) {
  return (value) => {
    if (value < min) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_min_value',
          description: { min },
        }),
      )
    }
  }
}
