export default function (chars = 20) {
  return (value) => {
    if (typeof value === 'string' && value.length > chars) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_max_length',
          description: chars,
        }),
      )
    }
  }
}
