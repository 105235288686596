import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLink,
  faEnvelope,
  faExternalLink,
  faChevronRight
} from '@fortawesome/pro-light-svg-icons'

export const LinkIcon = ({ className }) => (
  <FontAwesomeIcon className={className} icon={faLink} />
)

export const EnvelopeIcon = ({ className, size, color }) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faEnvelope}
    size={size}
  />
)

export const ExternalLinkIcon = ({ className, size, color }) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faExternalLink}
    size={size}
  />
)

export const RightIcon = ({ className, size, color }) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faChevronRight}
    size={size}
  />
)
