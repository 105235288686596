import React from 'react'
import { withValidator } from '../../../forms/validators/index'
import validate from '../../../forms/validators/index'
import emailIcon from './email.svg'
import IconInputField from '../IconInputField/IconInputField.jsx'
function EmailInputField(props) {
  return (
    <IconInputField
      {...props}
      icon={emailIcon}
      type="email"
      validate={validate.combine(validate.notEmpty(), validate.isEmail())}
    />
  )
}

export default withValidator()(EmailInputField)
