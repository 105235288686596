import React, { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import classnames from 'classnames'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useInfiniteScroll } from './InfiniteScrollListContext'
import style from './InfiniteScrollList.css'

export function InfiniteScrollList({
  children,
  containerClassName = '',
  dataLength,
  HeaderElement,
  scrollableTarget = '',
  hasMore,
  next,
  saveScrollPosition = true,
  masonryLayout = false,
  masonryLayoutColumns = { 350: 1, 750: 2, 900: 3 },
}) {
  const { setScrollTop, getScrollPosition } = useInfiniteScroll(
    saveScrollPosition,
  )
  const scrollContainer = useRef(null)
  const scrollableContainerId = `infiniteScrollContainer-${scrollableTarget}`
  const handleScroll = () =>
    setScrollTop &&
    setScrollTop(scrollableTarget, scrollContainer.current.scrollTop)

  return (
    <div className={classnames(style.container, containerClassName)}>
      {HeaderElement && HeaderElement}

      <div className={style.staticContainer}>
        <div
          id={scrollableContainerId}
          ref={scrollContainer}
          className={style.scrollContainer}
          onScroll={handleScroll}
        >
          {masonryLayout ? (
            <InfiniteScroll
              dataLength={dataLength}
              hasMore={hasMore}
              initialScrollY={
                saveScrollPosition && getScrollPosition(scrollableTarget)
              }
              next={next}
              pullDownToRefresh={false}
              scrollableTarget={scrollableContainerId}
            >
              <ResponsiveMasonry columnsCountBreakPoints={masonryLayoutColumns}>
                <Masonry>{children}</Masonry>
              </ResponsiveMasonry>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={dataLength}
              hasMore={hasMore}
              initialScrollY={
                saveScrollPosition && getScrollPosition(scrollableTarget)
              }
              next={next}
              pullDownToRefresh={false}
              scrollableTarget={scrollableContainerId}
            >
              {children}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  )
}
