export default function (password) {
  return (value) => {
    if (value !== password) {
      throw new Error(
        JSON.stringify({
          id: 'auth.passwords_doesnt_match',
        }),
      )
    }
  }
}
