import React from 'react'
import { Button } from '../../base/Button/Button.jsx'

import { useForm } from '../Form/FormContext'

export default function SubmitButton({ size, ...props }) {
  const { isDirty, hasErrors, isLoading, isSubmitting } = useForm()
  return (
    <Button
      disabled={!isDirty() || hasErrors() || isLoading() || isSubmitting()}
      size={size}
      type={Button.type.submit}
      {...props}
    />
  )
}
