import React from 'react'
import classnames from 'classnames'

import buttonStyle from './Button.css'

const display = Object.freeze({
  inline: 'inline',
  block: 'block',
})
const size = Object.freeze({
  small: 'small',
  medium: 'medium',
})
const type = Object.freeze({
  button: 'button',
  submit: 'submit',
  reset: 'reset',
})
const variant = Object.freeze({
  primary: 'primary',
  secondary: 'secondary',
  basic: 'basic',
})

/**
 * Button.
 *
 * @param {object} props - Props.
 * @param {*} props.children - Children.
 * @param {string} props.className - Class name.
 * @param {boolean} props.disabled - Disabled.
 * @param {'inline' | 'block'} [props.display='inline'] - Display.
 * @param {object} props.onClick - Click function.
 * @param {'small' | 'medium'} [props.size='medium'] - Size.
 * @param {boolean} props.squaredCorners - Squared corners.
 * @param {'button' | 'submit' | 'reset'} [props.type='button'] - Type attribute.
 * @param {'primary' | 'secondary' | 'basic'} [props.variant='primary'] - Variant.
 * @param {...object} props.props - Rest props.
 *
 * @example <Button className={style.homepage}>I'm a button</Button>
 */
export const Button = ({
  children,
  className,
  disabled,
  display = 'inline',
  onClick,
  size = 'medium',
  squaredCorners,
  type = 'button',
  variant = 'primary',
  ...props
}) => {
  return (
    <button
      type={type}
      className={classnames(
        buttonStyle.button,
        { [buttonStyle.small]: size === 'small' },
        { [buttonStyle.medium]: size === 'medium' },
        {
          [buttonStyle.primary]:
            variant === 'primary' ||
            (variant !== 'secondary' && variant !== 'basic'),
        },
        { [buttonStyle.secondary]: variant === 'secondary' },
        { [buttonStyle.basic]: variant === 'basic' },
        { [buttonStyle.disabled]: disabled },
        { [buttonStyle.displayBlock]: display === 'block' },
        { [buttonStyle.squaredCorners]: squaredCorners },
        className,
      )}
      onClick={onClick}
      data-test={props['data-test'] || `${type}-button`}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

/** @typedef {object} display Button display (default: inline) */
Button.display = display

/** @typedef {object} size Button size (default: medium) */
Button.size = size

/** @typedef {object} type Button type (default: button) */
Button.type = type

/** @typedef {object} variant Button variant (default: primary) */
Button.variant = variant
