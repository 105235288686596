import React, { useState, useEffect } from 'react'
import PhoneInput, {
  parsePhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input/input'
import classnames from 'classnames'

import style from '../style.css'
import { useForm } from '../Form/FormContext'
import { withValidator } from '../validators/index'

/**
 * Phone Input field.
 *
 * @component
 * @example
 * <PhoneInputField
 *   autoComplete="no"
 *   name="phone"
 *   label="Phone Number"
 *   placeholder="( ____ ) _____ - _______"
 *   country="US"
 * />
 */
function PhoneInputField({
  autoComplete = 'on',
  name,
  label = '',
  placeholder = '',
  country = 'US',
  required = false,
  showErrors = true,
  validate,
  localValidator,
  className,
}) {
  const { getData, updateField, getFormattedError, addValidator } = useForm()
  const [validateAdded, setValidateAdded] = useState()
  const error = getFormattedError(name)

  useEffect(() => {
    if (validate && !validateAdded) {
      addValidator(name, localValidator)
      setValidateAdded(true)
    }
  }, [validateAdded])

  function countryCallingCode(country) {
    return `+${getCountryCallingCode(country)}`
  }

  function handleChange(value = '') {
    let phone =
      parsePhoneNumber(value) && parsePhoneNumber(value).nationalNumber

    if (!phone) {
      phone = value.replace(countryCallingCode(country), '')
    }

    const error = localValidator(value, { getData })
    updateField(name, { value: phone, error })
  }

  function phoneInputFormat(phone) {
    return `${countryCallingCode(country)}${phone}`
  }

  return (
    <div className={classnames(style.formField, className)} {...{ required }}>
      {label && (
        <label
          className={classnames(style.formFieldLabel, {
            [style.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <PhoneInput
        autoComplete={autoComplete}
        className={classnames(style.formFieldInput, {
          [style.formFieldInputError]: error,
        })}
        name={name}
        placeholder={placeholder}
        country={country}
        value={phoneInputFormat(getData(name)) || ''}
        data-test={`${name}-input`}
        onChange={handleChange}
      />

      {showErrors && error && (
        <div
          data-test={`${name}-error-message`}
          className={style.formFieldErrorMessage}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export default withValidator()(PhoneInputField)
