export default function (min = 1, max = Infinity) {
  return (value) => {
    if ((value === undefined || value === null || value === '') && min > 0) {
      throw new Error(
        JSON.stringify({ id: 'reformulate.validate_selected_ensure' }),
      )
    }

    if (Array.isArray(value) && value.length < min) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_selected_min',
          description: { min },
        }),
      )
    }

    if (Array.isArray(value) && value.length > max) {
      throw new Error(
        JSON.stringify({
          id: 'reformulate.validate_selected_max',
          description: { max },
        }),
      )
    }
  }
}
